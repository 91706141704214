
import { EventBus } from '@/utils/event-bus.js';

export default {
  methods:{
    // closeModalForm() {
    //   // this.$refs.modalRef3.closedModal();
    //   EventBus.$emit('close-modal');

    // },
    triggerModal() {
      EventBus.$emit('open-modal');
    },
  },
  props: {
    label: {
      type: String,
      default: 'Bize Ulaşın',
    },
    ids: {
      type: String,
    },
    className: {
      type: String,
    },
  },
}
