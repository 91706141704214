export default {
  data() {
    return {
      breadcrumb: ''
    }
  },
  head() {
    try {
      if (this.seo && this.seo !== null) {
        this.seo.title = this.seo.title
          ? this.seo.title
          : this.page.title
            ? this.page.title
            : 'Mikro Yazılım'

        var placeholder = process.env.PLACEHOLDER_IMAGE

        if(this.seo.shareImage){
          this.image = this.seo.shareImage?.url
        }else {
          this.image = placeholder
        }

        // else if(this.shareImage){
        //   this.image = process.env.CDN_URL + this.page.image

        // if process.env.BASE_URL not includes www replace it
        var baseUrl = process.env.BASE_URL;
       /* if (!process.env.BASE_URL.includes('www')) {
          baseUrl = process.env.BASE_URL.replace(
            'https://',
            'https://www.'
          )
        }*/

         
        if(this.seo.canonical && this.seo.canonical !== null){
          // if canonical not includes www replace it
          if (!this.seo.canonical.includes('www')) {
            this.seo.canonical = this.seo.canonical.replace(
              'https://',
              'https://www.'
            )
          }
        }

        return {
          htmlAttrs: {
            lang: 'tr'
          },
          title:
            this.seo.title && this.seo.title !== null
              ? this.seo.title
              : this.page.title
                ? this.page.title
                : 'Mikro Yazılım',
          link: [
            {
              rel: 'icon',
              type: 'image/x-icon',
              href: '/favicon.ico',
            },
            {
              rel: 'canonical',
              href:
                this.seo.canonical && this.seo.canonical !== null
                  ? this.seo.canonical
                  : baseUrl + this.page.slug + '/'
                  
            },
          ],
          meta: [
            {
              hid: 'og:image',
              name: 'image',
              property: 'og:image',
              content: this.image
            },
            {
              hid: 'twitter:image',
              name: 'twitter:image',
              property: 'twitter:image',
              content: this.image

            },
            {
              hid: 'og:title',
              name: 'title',
              property: 'og:title',
              content:
                this.seo.title && this.seo.title !== null
                  ? this.seo.title
                  : this.page.title
                    ? this.page.title
                    : 'Mikro Yazılım',
            },
            {
              hid: 'og:description',
              name: 'og:description',
              property: 'og:description',
              content:
                this.seo?.description ? this.seo.description.slice(0, 200) :
                  this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                    this.page?.content ?
                      this.page.content.slice(0, 200)
                      : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
            {
              hid: 'description',
              name: 'description',
              property: 'description',
              content:
                this.seo?.description ? this.seo.description.slice(0, 200) :
                  this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                    this.page?.content ?
                      this.page.content.slice(0, 200)
                      : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
            {
              hid: 'og:url',
              name: 'url',
              property: 'og:url',
              content:
                this.seo.canonical && this.seo.canonical !== null
                  ? this.seo.canonical
                  : baseUrl + this.page.slug + '/',
            },
            {
              hid: 'og:site_name',
              name: 'site_name',
              property: 'og:site_name',
              content: 'Mikro Yazılım',
            },
            {
              hid: 'og:type',
              name: 'type',
              property: 'og:type',
              content: 'website',
            },
            {
              hid: 'mg:BirincilKampanya',
              name: 'mg:BirincilKampanya',
              property: 'mg:BirincilKampanya',
              content: this.seo.BirincilKampanya ? this.seo.BirincilKampanya : '7011v0000017bbDAAQ',
            },
            {
              hid: 'og:author',
              name: 'author',
              property: 'og:author',
              content: 'Mikro Yazılım',
            },
            {
              hid: 'og:type',
              name: 'type',
              property: 'og:type',
              content: 'website',
            },
            {
              hid: 'og:keywords',
              name: 'keywords',
              property: 'og:keywords',
              content: this.seo.keywords || 'Mikro Yazılım',
            },
            {
              hid: 'twitter:site',
              name: 'site',
              property: 'twitter:site',
              content: '@mikroyazilimas',
            },
            {
              hid: 'twitter:card',
              name: 'twitter:card',
              property: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              hid: 'twitter:creator',
              name: 'twitter:creator',
              property: 'twitter:creator',
              content: '@mikroyazilimas',
            },
            {
              hid: 'twitter:title',
              name: 'twitter:title',
              property: 'twitter:title',
              content:
                this.seo.title && this.seo.title !== null
                  ? this.seo.title
                  : this.page.title
                    ? this.page.title
                    : 'Mikro Yazılım',
            },
            {
              hid: 'twitter:description',
              name: 'twitter:description',
              property: 'twitter:description',
              content:
                this.seo?.description ? this.seo.description.slice(0, 200) :
                  this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                    this.page?.content ?
                      this.page.content.slice(0, 200)
                      : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
            {
              hid: 'twitter:image:alt',
              name: 'twitter:image:alt',
              property: 'twitter:image:alt',
              content:
                this.seo?.description ? this.seo.description.slice(0, 200) :
                  this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                    this.page?.content ?
                      this.page.content.slice(0, 200)
                      : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
          ],
        }
      } else {
        // this.seo === null
        return {
          title: 'Mikro Yazılım 1',
          link: [
            {
              rel: 'icon',
              type: 'image/x-icon',
              href: '/favicon.ico',
            },
          ],
          meta: [
            {
              hid: 'og:description',
              name: 'og:description',
              property: 'og:description',
              content:

                this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                  this.page?.content ?
                    this.page.content.slice(0, 200)
                    : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
            {
              hid: 'mg:BirincilKampanya',
              name: 'mg:BirincilKampanya',
              property: 'mg:BirincilKampanya',
              content: this.seo.BirincilKampanya ? this.seo.BirincilKampanya : '7011v0000017bbDAAQ',
            },
            {
              hid: 'description',
              name: 'description',
              property: 'description',
              content:
                this.page?.exceprt ? this.page.exceprt.slice(0, 200) :
                  this.page?.content ?
                    this.page.content.slice(0, 200)
                    : this.page?.title ?? 'Mikro Yazılım, her sektörden şirketin işini en üst düzeyde yönetecek ve verimli büyümelerini sağlayacak nitelikte yazılımlar geliştirir.'
            },
          ]
        }
      }
    } catch (ex) {
      return {
        title: 'Mikro Yazılım',
        htmlAttrs: {
          lang: 'tr'
        },
      }
    }
  },
}
