
// import UTILS from '@/mixins/utils'
// import CloseForm from '@/mixins/close-form.js'
// mixins : [CloseForm, UTILS],

export default {
  name: 'TabSlider',
  props: {
    
    promo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMobile: false,
      tabIndex: 0,
  
      showActionButtons: true,
      // documentTitle: '',
    }
  },
  // computed: {
  //   campaignQueries() {
  //     const queries = { ...this.$route.query }
  //     const queryString = Object.keys(queries)
  //       .map(
  //         (key) =>
  //           `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`
  //       )
  //       .join('&')
  //     return queryString
  //   },
  // },
  beforeMount() {
    // this.documentTitle = document.title
  },
  mounted() {
    this.breakpoints()
    window.addEventListener('resize', this.breakpoints, {
      passive: true,
    })
  },
  beforeDestroy() {
  },
  methods: {
   
    breakpoints() {
      this.isMobile = window.innerWidth < 992
    },
    tab(index) {
      this.tabIndex = index
    },
  },
}
